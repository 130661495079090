<!--
 * @Author: yy
 * @Date: 2021-09-30 10:32:18
 * @LastEditTime: 2021-12-16 17:08:26
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\views\testingCenter\errorReport.vue
-->
<template>
  <el-main>
    <div class="leftView">
      <div class="bgLine"></div>
      <div class="reportView">
        <div class="transitionBg"></div>
        <div class="report">
          <colorTitle class="colorTitle" v-if="testingType == 'error'">错题重测报告</colorTitle>
          <colorTitle class="colorTitle" v-else-if="testingType == 'train'" :title="reportInfo.label"></colorTitle>

          <div class="tips">
            <span>恭喜您完成此次训练！</span>
            <span>答题用时：{{ reportInfo.totalExaminationSecond | formatSeconds }}</span>
          </div>
          <sequence-bar :value="reportInfo.reportQuestions" @clickSquare="previewTesting" preview finish></sequence-bar>

          <div class="chartView">
            <v-progress-circular :rotate="-90" :value="getCorrectRate() || 0" :size="220" :width="24" color="primary">
              <div class="percentage">{{ getCorrectRate() || 0 }}%</div>
              <div>正确率</div>
            </v-progress-circular>
            <piemap :mapData="getPieMapData()" />
          </div>
        </div>
      </div>
    </div>
    <div class="rightView">
      <div class="primaryView" @click="toUrl('WrongQuestion')">
        <span v-if="testingType == 'error'">返回错题中心</span>
        <span v-else>进入错题中心</span>
        <v-icon>icon-enter</v-icon>
      </div>
      <div class="accentView type1" v-if="testingType == 'error'" @click="previewTesting()">
        <span>查看答题过程</span>
        <v-icon>icon-enter</v-icon>
      </div>
      <div class="warningView type2" v-if="testingType == 'train'" @click="previewTesting()">
        <span>查看答题过程</span>
        <v-icon>icon-enter</v-icon>
      </div>
      <div class="accentView type2" v-if="testingType == 'train'" @click="toUrl('TrainingCenter')">
        <span>返回训练中心</span>
        <v-icon>icon-enter</v-icon>
      </div>
      <div class="warningView type2" v-if="testingType == 'teacher'" @click="previewTesting()">
        <span>查看答题过程</span>
        <v-icon>icon-enter</v-icon>
      </div>
      <div class="accentView type2" v-if="testingType == 'teacher'" @click="toUrl('TeacherStudio')">
        <span>返回名师训练集</span>
        <v-icon>icon-enter</v-icon>
      </div>
    </div>
  </el-main>
</template>
<script>
import colorTitle from '@/components/public/colorTitle'
import sequenceBar from '@/components/testing/sequenceBar'
import piemap from '@/components/echarts/piemap'

export default {
  components: {
    colorTitle,
    sequenceBar,
    piemap,
  },
  data: () => ({
    reportInfo: {
      totalExaminationSecond:0
    },
  }),
  computed: {
    testingType() {
      return this.$route.query.type
    },
    examinationId() {
      return this.$route.query.examinationId
    },
  },
  mounted() {
    this.getExaminationReport()
  },
  methods: {
    async getExaminationReport() {
      let res
      let params = {
        examinationId: this.examinationId,
      }
      if (this.testingType == 'train') {
        res = await this.$service.getTrainExaminationReport(params)
      } else if (this.testingType == 'error') {
        res = await this.$service.getErrorExaminationReport(params)
      } else if (this.testingType == 'teacher') {
        res = await this.$service.getFamousTeacherTrainReport(params)
      }
      this.reportInfo = res
    },
    previewTesting(index) {
      this.$router.push({
        name: 'PreviewTesting',
        query: {
          type: this.testingType,
          examinationId: this.examinationId,
          index,
        },
      })
    },
    getCorrectRate() {
      let right = this.reportInfo.trueQuestionNum
      let error = this.reportInfo.falseQuestionNum
      let skip = this.reportInfo.skipQuestionNum
      let allNum = right + error + skip
      return Math.ceil((right / allNum) * 100)
    },
    getPieMapData() {
      let arr = []
      if (this.reportInfo.trueQuestionNum > 0) {
        arr.push({
          value: this.reportInfo.trueQuestionNum,
          name: '正确',
        })
      }
      if (this.reportInfo.falseQuestionNum > 0) {
        arr.push({
          value: this.reportInfo.falseQuestionNum,
          name: '错误',
        })
      }
      if (this.reportInfo.skipQuestionNum > 0) {
        arr.push({
          value: this.reportInfo.skipQuestionNum,
          name: '跳过',
        })
      }
      return arr
    },
    toUrl(name) {
      this.$router.push({
        name,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-main {
  padding: 40px 30px;
  overflow: hidden;
}

.leftView {
  width: 75vw;
  float: left;

  .bgLine {
    width: 23px;
    height: calc(100vh - 80px);
    box-shadow: 0px 1px 1px 0px rgba(17, 20, 85, 0.5);
    border-radius: 7px;
    border: 6px solid #0148ff;
    float: left;
  }

  .reportView {
    height: calc(100vh - 96px);
    margin: 8px 5px 0;
    border-radius: 0 20px 20px 0;
    position: relative;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.09);

    .transitionBg {
      background: linear-gradient(90deg,
          rgba(1, 71, 254, 0.22),
          rgba(255, 255, 255, 0.07) 59%,
          #fff 100%);
      width: 54px;
      height: 100%;
      position: absolute;
    }

    .report {
      height: 100%;
      padding: 48px 48px 48px 80px;
      overflow: auto;
      box-sizing: border-box;

      .colorTitle {
        margin-bottom: 34px;
      }

      .tips {
        color: #999999;
        font-size: 17px;
        margin-bottom: 50px;
        font-family: auto;
      }

      .tips span:last-child {
        margin-left: 20px;
      }

      .chartView {
        display: flex;
        align-items: center;
        justify-content: space-around;

        ::v-deep .v-progress-circular__info {
          display: block;
          text-align: center;

          div {
            color: #999;
            margin-top: -10px;
          }

          .percentage {
            color: #333;
            font-weight: 900;
            font-size: 50px;
            font-family: Akrobat-Black, Akrobat;
          }
        }

        ::v-deep .v-progress-circular__underlay {
          stroke: #e5ecfe;
        }
      }
    }
  }
}

.rightView {
  float: left;
  width: calc(25vw - 75px);
  margin-left: 15px;
  height: calc(100vh - 80px);
  padding: 8px 0;

  div {
    border-radius: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .primaryView {
    height: calc(50% - 10px);
    background: #0148ff;
    color: #fff;
  }

  .type1 {
    margin-top: 20px;
    height: calc(50% - 10px);
  }

  .type2 {
    margin-top: 20px;
    height: calc(25% - 15px);
  }

  .accentView {
    background: #ffe900;

    .theme--light.v-icon {
      color: #000;
    }
  }

  .warningView {
    background: #ffb13f;
    color: #fff;
  }

  .v-icon.v-icon {
    margin-left: 10px;
  }

  .theme--light.v-icon {
    color: #fff;
  }
}
</style>