<!--
 * @Author: yy
 * @Date: 2021-09-30 12:29:56
 * @LastEditTime: 2021-10-12 09:53:34
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\echarts\piemap.vue
-->

<template>
  <div>
    <div id="piemap" class="piemap"></div>
  </div>
</template>

<script>
export default {
  computed: {
    getMapData() {
      return this.mapData;
    },
  },
  watch: {
    getMapData() {
      this.getMyEcharts();
    },
  },
  props: {
    mapData: Array,
  },
  methods: {
    getMyEcharts() {
      this.drawMyChart();
    },
    drawMyChart() {
      var myChart = this.echarts.init(document.getElementById("piemap"));
      var option;
      option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        color: ["#6690fe", "#3069fb", "#0148ff"],
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            selectedMode: "single",
            data: this.mapData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
<style scoped lang="scss">
.piemap {
  width: 400px;
  height: 400px;
}
</style>
